import { useState } from "react";
import ChatPage from "./chat-page";
import CommentPage from "./comment-page";
import WavePage from "./wave-page";

export default function WaveController() {
    const [page, setPage] = useState(0);
    const [id, setID] = useState(0);

    const onNavigate = (index, wid) => {
        setPage(index)
        setID(wid)
    }

    return <div>
        <div className={page === 0 ? 'show' : 'hide'} ><WavePage navigate={onNavigate} /></div>
        {page === 1 && <ChatPage waveID={id} navigate={() => { setPage(0) }} />}
        {page === 2 && <CommentPage waveID={id} navigate={() => { setPage(0) }} />}
    </div>
}