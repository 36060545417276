import axios from "axios";
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from "react";
import { API } from "../utils/net-api";
import { useLocalStorage } from '@uidotdev/usehooks';
import { OffsetLimit } from '../parts/offset-limit';

export default function ChatPage({ navigate, waveID }) {
    const [rows, setRows] = useState()
    const [token] = useLocalStorage('token')

    const loadData = (offset, limit) => {
        axios.post(API.chats,
            { waveID: waveID, reverse: false, limit: limit, offset: offset },
            { headers: { 'Authorization': `Bear ${token}` } })
            .then((res) => {
                if (res.status !== 200) {
                    alert(`Get users is Error`);
                    return;
                }
                if (!res.data.Succeed) {
                    alert(res.data.Message);
                    return;
                }
                const data = res.data.Data.Messages
                setRows(data);
            })
            .catch((error) => {
                alert(`Get users is ${error}`)
            })
    }

    if (!rows) {
        loadData(0, 100);
    }

    const columns = [
        { field: 'Offset', headerName: 'ID', width: 75 },
        { field: 'Text', headerName: 'Content', width: 350 },
        { field: 'Quote', headerName: 'Quote', width: 350 },
        { field: 'User', headerName: 'User', valueGetter: (value, row) => row.User.FirstName, width: 150 },
        { field: 'PublishAt', headerName: 'Publish', valueGetter: (value, row) => row.PublishAt.substring(0, 10), width: 75 },
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    return (
        <div>
            <OffsetLimit hasBack={true} onBack={navigate} title='Chat:' onComfirm={loadData} />
            <Paper sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    getRowId={(row) => row.Offset}
                    sx={{ border: 0 }}
                />
            </Paper>
        </div>
    );
}

ChatPage.propTypes = {
    navigate: PropTypes.func.isRequired,
    waveID: PropTypes.number.isRequired,
};