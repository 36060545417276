import "@fontsource/lexend";
import "@fontsource/lexend/100.css";
import "@fontsource/lexend/200.css";
import "@fontsource/lexend/300.css";
import "@fontsource/lexend/400.css";
import "@fontsource/lexend/500.css";
import "@fontsource/lexend/600.css";
import "@fontsource/lexend/700.css";
import "@fontsource/lexend/800.css";
import "@fontsource/lexend/900.css";
import { Link } from "react-router-dom";

export default function HomePage() {
    return (
        <diV className='home' style={{ backgroudColor: '#000' }}>
            <div className="logo">
                <span className="logo">
                    Marlo
                </span>
                <a href='#download' className="download">Download</a>
            </div>

            <div className="title row">
            </div>

            <div className="info row">
                <img alt='' src="https://static.wixstatic.com/media/a1b568_122e5c961eab4f52b98b279ce13e2b00~mv2.png/v1/fill/w_310,h_390,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/PHOTO.png" />
                <div className="text">
                    <div>“</div>
                    <div>When the pandemic started, I was 26; by the end, I was 29. I wanted to go clubbing, but my friends had gotten married, had kids, moved away, or disappeared. Suddenly, I struggled to find a community in the city where I'd lived for 12 years. That's when I came up with 'Marlo' —because starting over socially is the new normal.</div>
                    <div>”</div>
                </div>
            </div>

            <div id="download" className='store row'>
                <div>
                    <div className="code">
                        <img alt='' src="https://static.wixstatic.com/media/a1b568_8c0a4f98900f4164965460e692f8fc1b~mv2.png/v1/fill/w_466,h_466,al_c,lg_1,q_85,enc_avif,quality_auto/a1b568_8c0a4f98900f4164965460e692f8fc1b~mv2.png" />
                        <img alt='' src="https://static.wixstatic.com/media/a1b568_4a2c7bbff415467abd90dcca8536d836~mv2.png/v1/fill/w_314,h_306,al_c,lg_1,q_85,enc_avif,quality_auto/a1b568_4a2c7bbff415467abd90dcca8536d836~mv2.png" />
                    </div>
                    <div className="explain">
                        <div>Download Marlo</div>
                        <div className="e-info">Download to make friends and have fun anytime, anywhere.</div>
                        <img alt='' src="https://static.wixstatic.com/media/a1b568_a45d8c90e6e5498ab15e3ce427afa0f1~mv2.png/v1/fill/w_358,h_116,al_c,lg_1,q_85,enc_avif,quality_auto/a1b568_a45d8c90e6e5498ab15e3ce427afa0f1~mv2.png" />
                    </div>
                </div>
            </div>

            <div className='connect'>
                <div>
                    <img alt='' src="https://static.wixstatic.com/media/a1b568_c467c1f4a6e9478ba7b99065b066e6b2~mv2.png/v1/fill/w_142,h_76,al_c,lg_1,q_85,enc_avif,quality_auto/a1b568_c467c1f4a6e9478ba7b99065b066e6b2~mv2.png" />
                    <span>&nbsp;&nbsp;© 2024 Marlo All rights reserved.</span>
                </div>
                <div>
                    <Link to='/admin'>Enter Backend</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="mailto:Hello@himarlo.com">
                        <img alt='' src='https://static.wixstatic.com/media/a1b568_47897ce9f2d64fe38ddd3f5d3642f587~mv2.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/a1b568_47897ce9f2d64fe38ddd3f5d3642f587~mv2.png' />
                    </a>&nbsp;&nbsp;
                    <a href="https://x.com/hi_marlo?s=21">
                        <img alt='' src="https://static.wixstatic.com/media/11062b_36edc9a0843e4563836cd26e2f9e5c3a~mv2.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/11062b_36edc9a0843e4563836cd26e2f9e5c3a~mv2.png" />
                    </a>&nbsp;&nbsp;
                    <a href="https://www.instagram.com/himarlo_us?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">
                        <img alt='' src="https://static.wixstatic.com/media/11062b_cef3b719166a4815b446d4dcfcb6120d~mv2.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/11062b_cef3b719166a4815b446d4dcfcb6120d~mv2.png" />
                    </a>
                </div>
            </div>
        </diV>
    )
}