import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContext } from "./utils/user-context";
import { useLocalStorage } from "@uidotdev/usehooks";
import HomePage from "./pages/home-page";
import LoginPage from "./pages/login-page";
import BackendPage from "./pages/backend-page";
import ErrorPage from "./pages/error-page";

const AdminRoutes = () => {
  const [isAuth] = useContext(UserContext);
  return isAuth ? (<BackendPage />) : (<LoginPage />);
}

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [token] = useLocalStorage('token');

  useEffect(() => {
    if (!!token) {
      setIsAuth(true);
    }
  }, [token])

  return (
    <BrowserRouter future={{ v7_relativeSplatPath: true }}>
      <UserContext.Provider value={[isAuth, setIsAuth]}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/admin" element={<AdminRoutes />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App