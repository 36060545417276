import * as React from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from "react";
import { API } from "../utils/net-api";
import { useLocalStorage } from '@uidotdev/usehooks';
import { Button, ButtonGroup } from '@mui/material';
import { WaveDetail } from '../parts/wave-detail';
import { OffsetLimit } from '../parts/offset-limit';
import InfoIcon from '@mui/icons-material/Info';
import FeedIcon from '@mui/icons-material/Feed';
import ForumIcon from '@mui/icons-material/Forum';

export default function WavePage({ navigate }) {
    const [rows, setRows] = useState()
    const [open, setOpen] = useState(false)
    const [row, setRow] = useState()
    const [token] = useLocalStorage('token')

    const loadData = (offset, limit) => {
        axios.post(API.waves,
            { limit: limit, offset: offset },
            { headers: { 'Authorization': `Bear ${token}` } })
            .then((res) => {
                if (res.status !== 200) {
                    alert(`Get users is Error`);
                    return;
                }
                if (!res.data.Succeed) {
                    alert(res.data.Message);
                    return;
                }
                const data = res.data.Data
                setRows(data);
            })
            .catch((error) => {
                alert(`Get users is ${error}`)
            })
    }

    if (!rows) {
        loadData(0, 100);
    }

    const columns = [
        { field: 'ID', headerName: 'ID', width: 75 },
        { field: 'Title', headerName: 'Title', width: 200 },
        { field: 'Body', headerName: 'Body', minWidth: 350 },
        { field: 'ExpiredAt', headerName: 'Expired', valueGetter: (value, row) => row.ExpiredAt.substring(0, 10), minWidth: 75 },
        { field: 'User', headerName: 'User', valueGetter: (value, row) => { return !row.User ? row.UserID : row.User.FirstName }, width: 150 },
        {
            field: 'Action', headerName: 'Action', renderCell: (params) => {
                const onClickDetail = (e) => {
                    e.stopPropagation();
                    setRow(params.row);
                    setOpen(true);
                }

                return (
                    <ButtonGroup sx={{ p: 1 }} size='small' variant="text" aria-label="Basic button group">
                        <Button onClick={onClickDetail} ><InfoIcon /></Button>
                        <Button onClick={() => { navigate(1, params.row.ID); }}><ForumIcon /></Button>
                        <Button onClick={() => { navigate(2, params.row.ID); }}><FeedIcon /></Button>
                    </ButtonGroup>
                );
            }, width: 150,
        },
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    return (
        <div>
            <OffsetLimit hasBack={false} onBack={() => { }} title='Waves:' onComfirm={loadData} />
            <Paper sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    getRowId={(row) => row.ID}
                    sx={{ border: 0 }}
                />
            </Paper>
            <WaveDetail value={row} open={open} onClose={() => setOpen(false)} />
        </div>
    );
}

WavePage.propTypes = {
    navigate: PropTypes.func.isRequired,
};