import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export function WaveDetail(props) {
    const { value, open, onClose } = props

    return (
        <BootstrapDialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title" >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Ware Detail</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <table>
                    <tr>
                        <th>ID: </th>
                        <td>{value?.ID}</td>
                    </tr>
                    <tr>
                        <th>Title: </th>
                        <td>{value?.Title}</td>
                    </tr>
                    <tr>
                        <th>Body: </th>
                        <td>{value?.Body}</td>
                    </tr>
                    <tr>
                        <th>Capacity: </th>
                        <td>{value?.Capacity}</td>
                    </tr>
                    <tr>
                        <th>OpenToAll: </th>
                        <td>{value?.OpenToAll.toString()}</td>
                    </tr>
                    <tr>
                        <th>AllowToSpread: </th>
                        <td>{value?.AllowToSpread.toString()}</td>
                    </tr>
                    <tr>
                        <th>IsPermanent:  </th>
                        <td>{value?.IsPermanent.toString()}</td>
                    </tr>
                    <tr>
                        <th>ExpiredAt: </th>
                        <td>{value?.ExpiredAt.substring(0, 10)}</td>
                    </tr>
                    <tr>
                        <th>CreatedAt: </th>
                        <td>{value?.CreatedAt.substring(0, 10)}</td>
                    </tr>
                    <tr>
                        <th>User:  </th>
                        <td>{value?.User.FirstName}</td>
                    </tr>
                </table>
            </DialogContent>
        </BootstrapDialog >
    )
}

WaveDetail.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};