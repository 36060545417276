import React, { useContext } from 'react';
import axios from 'axios';
import { AppProvider } from '@toolpad/core/AppProvider';
import { SignInPage } from '@toolpad/core/SignInPage';
import { useTheme } from '@mui/material/styles';
import { useLocalStorage } from "@uidotdev/usehooks";
import { UserContext } from '../utils/user-context';
import { API } from '../utils/net-api';

const providers = [{ id: 'credentials', name: 'Email and Password' }];

export default function LoginPage() {
  const theme = useTheme();
  const [, setIsAuth] = useContext(UserContext);
  const [user, setUser] = useLocalStorage('user');
  const [token, setToken] = useLocalStorage('token');

  const BRANDING = {
    title: 'Marlo',
  };

  const loginHandle = async (provider, formData) => {
    const json = JSON.stringify(Object.fromEntries(formData))
    return axios.post(API.login, json)
      .then((res) => {
        if (res.status !== 200) {
          alert(`Signin in with ${provider.name} is Error`);
          return;
        }
        if (!res.data.Succeed) {
          alert(res.data.Message);
          return;
        }
        const data = res.data.Data
        setUser(data.User);
        setToken(data.Token);
        setIsAuth(true);
        console.log('******', user, token)
      })
      .catch((error) => {
        alert(`Signin in with ${provider.name}: ${error}`)
      })
  };

  return (
    // preview-start
    <AppProvider branding={BRANDING} theme={theme}>
      <SignInPage signIn={loginHandle} providers={providers} />
    </AppProvider>
    // preview-end
  );
}
