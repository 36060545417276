// import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    // const error = useRouteError();
    return (
        <div>
            <p style={{ color: "red", fontSize: "30px" }}>
                {/* {error.status === "404" ? "404 Page Not Found" : error} */}
                404
            </p>
        </div>
    );
}