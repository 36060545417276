import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import EmojiFlags from '@mui/icons-material/EmojiFlags';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { UserContext } from '../utils/user-context';
import { createTheme } from '@mui/material/styles';
import { useDemoRouter } from '@toolpad/core/internal';
import { useLocalStorage } from "@uidotdev/usehooks";
import { Button, Box } from '@mui/material';
import UserPage from './user-page';
import WaveController from './wave-controller';

const NAVIGATION = [
  {
    segment: 'wave',
    title: 'Waves',
    icon: <EmojiFlags />,
  },
  {
    segment: 'user',
    title: 'Users',
    icon: <AssignmentInd />,
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function ContentPage({ pathname }) {
  switch (pathname) {
    case '/user':
      return <UserPage />
    default:
      return <WaveController />
  }
}

ContentPage.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function UserInfo() {
  const [, setIsAuth] = useContext(UserContext)
  const [user, setUser] = useLocalStorage('user');
  const [, setToken] = useLocalStorage('token');

  const logoutHandle = () => {
    setUser(null);
    setToken(null);
    setIsAuth(false);
  }

  return (
    <React.Fragment>
      <span className='tool-email'>{user.Email} </span>
      <Button onClick={logoutHandle}> Logout</Button>
    </React.Fragment>
  );
}

function BackendPage(props) {
  const { window } = props;
  const router = useDemoRouter('/dashboard');

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  return (
    // preview-start
    <AppProvider
      navigation={NAVIGATION}
      branding={{
        logo: <img src="https://mui.com/static/logo.png" alt="MUI logo" />,
        title: 'Marlo Backend',
      }}
      router={router}
      theme={demoTheme}
      window={demoWindow}
    >
      <DashboardLayout slots={{ toolbarAccount: UserInfo }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <ContentPage pathname={router.pathname} navigate={router.navigate} />
        </Box>
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

BackendPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default BackendPage;
