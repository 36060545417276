import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export function UserDetail(props) {
    const { value, open, onClose } = props

    return (
        <BootstrapDialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title" >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Ware Detail</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <table>
                    <tr>
                        <th>ID: </th>
                        <td>{value?.ID}</td>
                    </tr>
                    <tr>
                        <th>Name: </th>
                        <td>{value?.FirstName}</td>
                    </tr>
                    <tr>
                        <th>Phone: </th>
                        <td>{value?.PhoneNumber}</td>
                    </tr>
                    <tr>
                        <th>Bio: </th>
                        <td>{value?.BioText}</td>
                    </tr>
                    <tr>
                        <th>Pronoun: </th>
                        <td>{['Male', 'Female', 'Nonbinary'][value?.Pronoun]}</td>
                    </tr>
                    <tr>
                        <th>Mbti: </th>
                        <td>{value?.Bmti}</td>
                    </tr>
                    <tr>
                        <th>Interests:  </th>
                        <td>{value?.Interests}</td>
                    </tr>
                    <tr>
                        <th>Instagram: </th>
                        <td>{value?.InstagramAccount}</td>
                    </tr>
                    <tr>
                        <th>Twitter: </th>
                        <td>{value?.TwitterAccount}</td>
                    </tr>
                    <tr>
                        <th>LinkedIn: </th>
                        <td>{value?.LinkedInAccount}</td>
                    </tr>
                    <tr>
                        <th>BirthDate: </th>
                        <td>{value?.BirthDate.substring(0, 10)}</td>
                    </tr>
                    <tr>
                        <th>CreatedAt: </th>
                        <td>{value?.CreatedAt.substring(0, 10)}</td>
                    </tr>
                </table>
            </DialogContent>
        </BootstrapDialog >
    )
}

UserDetail.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};