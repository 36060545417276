import * as React from 'react';
import axios from "axios";
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from "react";
import { API } from "../utils/net-api";
import { useLocalStorage } from '@uidotdev/usehooks';
import { Button } from '@mui/material';
import { UserDetail } from '../parts/user-detail';
import { OffsetLimit } from '../parts/offset-limit';
import InfoIcon from '@mui/icons-material/Info';

export default function UserPage({ navigate }) {
    const [rows, setRows] = useState()
    const [open, setOpen] = useState(false)
    const [row, setRow] = useState()
    const [token] = useLocalStorage('token')

    const loadData = (offset, limit) => {
        axios.post(API.users,
            { limit: limit, offset: offset },
            { headers: { 'Authorization': `Bear ${token}` } })
            .then((res) => {
                if (res.status !== 200) {
                    alert(`Get users is Error`);
                    return;
                }
                if (!res.data.Succeed) {
                    alert(res.data.Message);
                    return;
                }
                const data = res.data.Data
                setRows(data);
            })
            .catch((error) => {
                alert(`Get users is ${error}`)
            })
    }

    if (!rows) {
        loadData(0, 100);
    }

    const columns = [
        { field: 'ID', headerName: 'ID', width: 75 },
        { field: 'FirstName', headerName: 'Name', width: 150 },
        { field: 'PhoneNumber', headerName: 'Phone', minWidth: 200 },
        { field: 'BioText', headerName: 'Bio', minWidth: 250 },
        { field: 'Pronoun', headerName: 'Pronoun', valueGetter: (value, row) => ['Male', 'Female', 'Nonbinary'][row.Pronoun], minWidth: 100 },
        { field: 'BirthDate', headerName: 'BirthDay', valueGetter: (value, row) => row.BirthDate.substring(0, 10), width: 150 },
        {
            field: 'Action', headerName: 'Action', renderCell: (params) => {
                const onClickDetail = (e) => {
                    e.stopPropagation();
                    setRow(params.row);
                    setOpen(true);
                }

                return (
                    <Button size="small" onClick={onClickDetail}><InfoIcon /></Button>
                );
            }, width: 75,
        }
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    return (
        <div>
            <OffsetLimit hasBack={false} onBack={() => { }} title='Users:' onComfirm={loadData} />
            <Paper sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    getRowId={(row) => row.ID}
                    sx={{ border: 0 }}
                />
            </Paper>
            <UserDetail value={row} open={open} onClose={() => setOpen(false)} />
        </div>
    );
}